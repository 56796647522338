import React, { createRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Typography, ListItemButton, Menu } from "@mui/material";

export const NavItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  transition: padding var(----transition-settings-1, 0.2s ease);
`;

const SubMenuIconContainer = styled.div`
  svg {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    margin-top: 3px;
  }
`;
export const SubNavContainer = styled(Menu)`
  .MuiMenu-paper {
    width: 240px;
    padding: 0 10px;
    border-radius: 20px;
    background-color: #e6ecf8;
  }

  svg {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    fill: #656370;
    margin-top: 3px;
  }

  section {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 30px;
    padding: 3px 35px;
    margin: 10px 0px;
    cursor: pointer;
    &:hover {
      background: rgba(0, 0, 0, 0.08);
    }
    &.active {
      section {
        background: #96b4e3;
        color: var(--on-primary-accent, #000);

        svg {
          fill: #464c5e;
        }
      }

      p {
        font-weight: 600;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: background var(--transition-settings-1, 0.2s ease);
    width: 100%;
  }

  a.active {
    section {
      background: #96b4e3;
      color: var(--on-primary-accent, #000);

      svg {
        fill: #464c5e;
      }
    }

    p {
      font-weight: 600;
    }
  }
`;

export const IconContainer = styled(ListItemButton)`
  width: 56px;
  height: 32px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  svg {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    fill: #656370;
    margin-top: 3px;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const NavItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.25rem;
  padding: 0;

  a {
    padding: 0.15rem 0rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: inherit;
    text-decoration: none;
    transition: background var(--transition-settings-1, 0.2s ease);
    cursor: pointer;
    width: 100%;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
    p {
      text-align: center;
    }
    section .active {
      ${IconContainer} {
        background: #96b4e3;
        color: var(--on-primary-accent, #fff);
        border-radius: 30px;

        svg {
          fill: #464c5e;
        }
      }
    }
  }

  a.active {
    ${IconContainer} {
      background: #96b4e3;
      color: var(--on-primary-accent, #fff);
      border-radius: 30px;

      svg {
        fill: #464c5e;
      }
    }

    p {
      font-weight: 600;
    }
  }
`;

function PopOverNavItems({ children, label, icon: Icon }) {
  const location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);

  const isActive = children.some(
    (child) => child.props.to === location.pathname
  );
  const handleMouseEnter = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <NavItemContainer>
      <NavLink to={children[0].props.to} className={isActive ? "active" : ""}>
        <section
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <IconContainer onMouseEnter={handleMouseEnter}>
            {Icon && <Icon />}
          </IconContainer>
          <Typography
            fontSize={11}
            letterSpacing={0.1}
            fontWeight={500}
            mt={0.5}
            ml={0.5}
          >
            {label}
          </Typography>

          <SubNavContainer
            open={open}
            anchorEl={anchorEl}
            onMouseLeave={handleMouseLeave}
            sx={{ ml: 17, mt: 2 }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            MenuListProps={{
              onMouseLeave: handleMouseLeave,
            }}
            hideBackdrop
          >
            {children.map((child, index) => {
              const {
                props: { label, to, icon: Icon },
              } = child;

              return (
                <NavLink
                  key={index}
                  to={to}
                  onClick={(e) => {
                    setAnchorEl(null);
                    e.stopPropagation();
                  }}
                >
                  <section>
                    <SubMenuIconContainer>
                      {Icon && <Icon />}
                    </SubMenuIconContainer>
                    <Typography
                      fontSize={11}
                      letterSpacing={0.1}
                      fontWeight={500}
                      mt={0.5}
                      ml={0.5}
                    >
                      {label}
                    </Typography>
                  </section>
                </NavLink>
              );
            })}
          </SubNavContainer>
        </section>
      </NavLink>
    </NavItemContainer>
  );
}

export function NavItem({ children, to, label, icon: Icon }) {
  const item = createRef();

  if (children) {
    return (
      <PopOverNavItems icon={Icon} label={label}>
        {children}
      </PopOverNavItems>
    );
  }
  return (
    <NavItemContainer>
      <NavLink to={to} ref={item}>
        <section>
          <IconContainer>{Icon && <Icon />}</IconContainer>
          <Typography
            fontSize={11}
            letterSpacing={0.1}
            fontWeight={500}
            mt={0.5}
            ml={0.5}
          >
            {label}
          </Typography>
        </section>
      </NavLink>
    </NavItemContainer>
  );
}
