import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Collapse, ListItemButton, Typography } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import HomeIcon from "@mui/icons-material/Home";

import { NavLink, useNavigate } from "react-router-dom";
import { Logo } from "../../assets/icons";
import { NavItemsContainer } from "./containers";
import dashboardItems from "./dashboard-items";

const StyledNavBox = styled(Box)`
  width: 250px;
  padding: 20px;
`;

const BrandIcon = styled(Logo)`
  width: 25px;
  height: 25px;
`;

const Brand = styled(ListItemButton)`
  font-size: 20px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 14px;
  padding: 10px 12px;
`;

const NavItem = styled(NavLink)`
  display: flex;
  gap: 10px;
  padding: 10px 12px;
  cursor: pointer;
  transition: 0.3s;
  color: #7e7c88;
  text-decoration: none;
  border-radius: 8px;
  align-items: flex-end;

  &.active {
    background-color: #96b4e3;
    color: #566179;
  }
`;

const ItemLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

const ExpandIcon = styled(Box)`
  margin-left: auto;
`;

const ChildNavItem = styled(NavItem)`
  margin-left: 14px;
  border-left: 1px solid #7e7c88;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

const iconStyle = { width: 25, height: 25 };
interface MobileSidebarProps {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const MobileSidebar = ({ toggleDrawer }: MobileSidebarProps) => {
  const navigate = useNavigate();
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const handleItemClick = (event: React.MouseEvent, item: any) => {
    if (item.children) {
      event.preventDefault();
      event.stopPropagation();
      setOpenItems((prev) => ({ ...prev, [item.title]: !prev[item.title] }));
    } else {
      navigate(item.href);
    }
  };

  return (
    <StyledNavBox role="presentation" onClick={toggleDrawer(false)}>
      <Brand onClick={() => navigate("/dashboard")}>
        <BrandIcon /> Workspace
      </Brand>

      <NavItem to="/dashboard">
        <HomeIcon sx={iconStyle} />
        <ItemLabel>Dashboard</ItemLabel>
      </NavItem>

      <NavItemsContainer>
        {dashboardItems.map((item, index) => {
          const { title, href, icon: Icon, children } = item;

          return (
            <React.Fragment key={index}>
              <NavItem to={href} onClick={(e) => handleItemClick(e, item)} end>
                {Icon && <Icon sx={iconStyle} />}
                <ItemLabel>{title}</ItemLabel>
                {children && (
                  <ExpandIcon sx={{ width: 25, height: 20, pb: 7 }}>
                    {openItems[title] ? (
                      <ExpandLessIcon sx={iconStyle} />
                    ) : (
                      <ExpandMoreIcon sx={iconStyle} />
                    )}
                  </ExpandIcon>
                )}
              </NavItem>
              {children && (
                <Collapse in={openItems[title]} timeout="auto" unmountOnExit>
                  {children.map((child, childIndex) => (
                    <ChildNavItem
                      key={childIndex}
                      to={child.href}
                      onClick={toggleDrawer(false)}
                      end
                    >
                      {child.icon && <child.icon sx={iconStyle} />}
                      <ItemLabel>{child.title}</ItemLabel>
                    </ChildNavItem>
                  ))}
                </Collapse>
              )}
            </React.Fragment>
          );
        })}
      </NavItemsContainer>
    </StyledNavBox>
  );
};

export default MobileSidebar;
