import {
  Autocomplete,
  TextField,
  Button as MuiButton,
  Box as MuiBox,
  Typography,
  AutocompleteRenderInputParams,
  TextFieldProps,
  AutocompleteProps,
  SxProps,
  Theme,
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@mui/material";
import { spacing } from "@mui/system";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { ReactNode } from "react";
import styled from "styled-components";

const DatePicker = styled(MuiDatePicker)`
  .MuiOutlinedInput-root {
    border-radius: 8px;
  }
`;

const Form = styled.form`
  height: 100%;
  min-width: 350px;
  max-width: 400px;
  > * {
    margin-bottom: 15px;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`;
const Button = styled(MuiButton)(spacing);

const Box = styled(MuiBox)`
  display: flex;
  align-items: center;
`;

const CustomTextarea = styled(TextareaAutosize)(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, 0.23)", 
  borderRadius: "8px",
  padding: theme.spacing(4),
  fontSize: "16px",
  width: "100%",
  resize: "none", 
  color: theme.palette.text.primary,
  font: "inherit",
  "&:focus": {
    outline: "none",
    borderColor: "#0e0d8e",
  },
  "&:hover": {
    outline: "none",
    borderColor: "#020024"
  },
}));

interface Option {
  value: string;
  label: string;
}

interface CustomAutoCompleteProps {
  renderInput: (params: AutocompleteRenderInputParams) => JSX.Element;
  options: Option[];
}

export const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  renderInput,
  options,
  ...props
}) => {
  return (
    <Autocomplete renderInput={renderInput} options={options} {...props} />
  );
};

export const CustomDatePicker = ({ ...props }) => {
  return <DatePicker {...props} />;
};
interface CustomTextFieldProps extends Omit<TextFieldProps, "children"> {
  children?: ReactNode;
  sx?: SxProps<Theme>;
}

export const CustomTextField: React.FC<CustomTextFieldProps> = ({
  children,
  ...props
}) => {
  return (
    <TextField
      InputProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
      {...props}
      sx={{
        ".MuiOutlinedInput-root": {
          borderRadius: 2,
        },
      }}
    >
      {children}
    </TextField>
  );
};

export const CustomTextareaField = ({
  name,
  value,
  onChange,
  error,
  helperText,
  ...props
}: {
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  helperText?: string | boolean;
} & TextareaAutosizeProps) => (
  <CustomTextarea
      name={name}
      value={value}
      onChange={onChange}
      {...props}
    />  
);

interface StyledAutocompleteProps<T>
  extends AutocompleteProps<T, false, false, false> {
  label: string;
  error?: boolean;
  helperText?: string;
  sx?: SxProps<Theme>;
}

export const StyledAutocomplete = <T,>({
  label,
  error,
  helperText,
  ...props
}: StyledAutocompleteProps<T>) => {
  return (
    <Autocomplete
      {...props}
      sx={{
        ".MuiOutlinedInput-root": {
          borderRadius: 2,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};

interface FormContainerProps {
  children: ReactNode;
  action: () => void;
}

export const FormContainer = ({ children, action }: FormContainerProps) => {
  return <Form onSubmit={action}>{children}</Form>;
};

interface SubmitButtonProps {
  action?: () => void;
  title: string;
}

export const SubmitButton = ({ title, action }: SubmitButtonProps) => {
  return (
    <Box display="flex" justifyContent="flex-end" mt={4}>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={action}
        sx={{ borderRadius: "50px", padding: "10px 20px" }}
      >
        <Typography fontWeight={500}>{title}</Typography>
      </Button>
    </Box>
  );
};